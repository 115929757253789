import FAQ from '@/components/shared/FAQ';
import { Section } from '@/components/core';

const FAQs = (props) => (
  <Section noOverflow={props.noOverflow} data-testid="faqs-section">
    <FAQ {...props} />
  </Section>
);

export default FAQs;
