import theme from '@/utils/styles-variables';

import { Container } from '@/components/core/flexbox';
import List from './List';
import { StyledSection, Shape } from './SolutionBenefitsStyles';
import { Intro } from '@/components/shared';

export default function SolutionBenefits({ content, backgroundShape, alignLeft }) {
  if (!content) return null;
  const { item, subtitle, title, upperLabel } = content;

  return (
    <StyledSection
      color={theme.colors.neutral.default}
      lastColored
      $backgroundShape={backgroundShape}
    >
      {backgroundShape && <Shape data-testid="shape" />}
      <Intro
        upperLabel={upperLabel}
        title={title}
        description={subtitle}
        alignMobile="left"
        alignTablet="left"
        align={alignLeft ? 'left' : 'center'}
        size="sm"
        as="header"
        innerSpacer
      />
      <Container>
        <List list={item} />
      </Container>
    </StyledSection>
  );
}
