import styled, { css } from 'styled-components';
import { Section } from '@/components/core';

export const StyledSection = styled(Section)`
  ${(props) =>
    props.$backgroundShape &&
    css`
      > div {
        overflow: clip;
        position: relative;
      }
    `};
`;

export const Shape = styled.div`
  background: ${(props) =>
    `linear-gradient(186.41deg, ${props.theme.colors.dark_neutral} -28.95%, ${props.theme.colors.dark_neutral}00 55.5%)`};
  border-radius: 50%;
  top: 35%;
  height: 960px;
  position: absolute;
  right: 56%;
  width: 960px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;
