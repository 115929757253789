import styled from 'styled-components';

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    max-width: 366px;
    flex: 1;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: inline-flex;
    column-gap: 23px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: unset;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    column-gap: 24px;
  }
`;

export default Wrapper;
