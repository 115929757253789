import styled from 'styled-components';

const ImageWrapper = styled.div`
  position: relative;
  width: 75px;
  height: 68px;
  min-width: 75px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 60px;
    width: 67px;
    min-width: 67px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 75px;
    height: 68px;
    min-width: 75px;
  }
`;

export default ImageWrapper;
