import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import Wrapper from '@/components/shared/ColoredCards/Wrapper';

export default function MobileSlider({ children, bg }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <MobileWrapper bg={bg}>
      <Slider {...settings}>{children}</Slider>
    </MobileWrapper>
  );
}

const MobileWrapper = styled(Wrapper)`
  padding: 0;

  ${(props) =>
    props.bg &&
    css`
      background-color: ${props.bg};
    `};
`;
