import Slider from 'react-slick';
import styled, { css } from 'styled-components';

import { Marquee, Section } from '@/components/core';

import { StyledSliderWrapper } from '@/components/solutions/digital-therapy/EngagingClinicalOutcomes/EngagingClinicalOutcomes-styling';
import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import ImageCard from './ImageCard';
import QuoteCard from './QuoteCard';
import { Intro } from '@/components/shared';

const Wrapper = styled.div`
  ${(props) =>
    props.colored &&
    css`
      padding-top: 136px;
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        padding-top: 104px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding-top: 64px;
      }
    `};
`;

const QuoteCardWrapper = styled.div`
  display: flex;

  ${(props) =>
    props.inverted &&
    css`
      flex-direction: row-reverse;
    `};
`;

function renderSlider(quotes, colored, inverted) {
  return quotes.map((quote) => {
    return (
      <QuoteCardWrapper key={`q${quote.id}`} inverted={inverted}>
        <QuoteCard quote={quote.quote} author={quote.author} role={quote.role} colored={colored} />
        {quote.image?.data && <ImageCard image={quote.image} colored={colored} />}
      </QuoteCardWrapper>
    );
  });
}

function renderMarquee(quotes, colored) {
  const ceil = Math.ceil(quotes.length / 2);

  const slider1 = quotes.slice(0, ceil);
  const slider2 = quotes.slice(ceil);

  if (quotes.length % 2 !== 0) {
    //  if odd add an entry in the second array
    slider2.push(slider1[1]);
  }

  return (
    <div data-testid="marquee-content">
      <Marquee speedFactor={20} height={314} full>
        {renderSlider(slider1, colored)}
      </Marquee>

      <Marquee speedFactor={20} invertedScroll height={360} full>
        {renderSlider(slider2, colored, true)}
      </Marquee>
    </div>
  );
}
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  customPaging: (i) => <button type="button">{i + 1}</button>,
};

const Testimonials = ({ content, colored, spacerTop }) => {
  const mobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  if (!content || !content.quotes || !content.quotes.length) return null;

  const { title, upperLabel, quotes } = content;
  return (
    <Section
      color={colored ? theme.colors.neutral.default : null}
      md="66"
      sm="34"
      spacerTop={spacerTop}
    >
      <Wrapper colored={colored}>
        <Intro upperLabel={upperLabel} title={title} as="header" innerSpacer />

        {mobile ? (
          <StyledSliderWrapper data-testid="mobile-cards">
            <Slider {...settings}>
              {quotes.map((item) => (
                <QuoteCard
                  key={item.id}
                  quote={item.quote}
                  author={item.author}
                  role={item.role}
                  colored={colored}
                />
              ))}
            </Slider>
          </StyledSliderWrapper>
        ) : (
          renderMarquee(quotes, colored)
        )}
      </Wrapper>
    </Section>
  );
};

export default Testimonials;
