import styled from 'styled-components';
import { Section } from '@/components/core';

const Wrapper = styled(Section)`
  overflow: hidden;

  .slick-track {
    display: grid;
    grid-auto-flow: column;
    grid-auto-rows: 1fr;
  }

  .slick-slide {
    padding: 0 24px;

    > div {
      display: grid;
      height: 100%;
    }
  }

  .slick-dots {
    bottom: auto;
    padding-top: 16px;
    position: relative;

    li {
      width: 8px;
      height: 8px;
      margin: 0 4px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 8px;
        height: 8px;

        &:before {
          position: relative;
          content: '';
          width: 8px;
          height: 8px;
          background-color: transparent;
          border: 2px solid ${(props) => props.theme.colors.grey[500]};
          border-radius: 50%;
          opacity: 1;
        }
      }
    }

    .slick-active button:before {
      background-color: ${(props) => props.theme.colors.grey[500]};
    }
  }
`;

export default Wrapper;
