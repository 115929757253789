import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 83px;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    row-gap: 48px;
  }
`;

export default Wrapper;
