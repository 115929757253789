import styled, { css } from 'styled-components';
import Image from 'next/image';

const Wrapper = styled.div`
  position: relative;
  height: 274px;
  width: 430px;
  margin-left: 24px;
  display: block;
  border-radius: 8px;
  overflow: hidden;

  ${(props) =>
    props.colored &&
    css`
      box-shadow: 0 10px 30px rgba(239, 233, 230, 0.8);
    `};

  img {
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    height: 290px;
    width: 467px;
  }
`;

const ImageCard = ({ image, colored }) => {
  if (!image) return null;
  return (
    <Wrapper colored={colored}>
      <Image
        data-testid="image-card"
        src={image?.data?.attributes?.url}
        alt={image?.data?.attributes?.alternativeText}
        fill
        sizes="(min-width: 768px) 467px, 430px"
      />
    </Wrapper>
  );
};

export default ImageCard;
