import Item from './Item';
import Wrapper from './Wrapper';

const List = ({ list }) => {
  if (!list) return null;

  return (
    <Wrapper>
      {list?.map((item) => (
        <Item item={item} key={item.name} />
      ))}
    </Wrapper>
  );
};

export default List;
