import styled from 'styled-components';
import { Col } from '@/components/core/flexbox';
import { H3 } from '@/components/core/Title';

const CardWrapper = styled.div`
  padding: 0 24px;
`;

const CustomH3 = styled(H3)`
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  padding-bottom: 0;
  margin-bottom: 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-bottom: 56px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 25px;
    line-height: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const CustomCol = styled(Col)`
  padding-top: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    &:nth-child(2n + 1) {
      padding-right: 12px;
    }

    &:nth-child(2n + 2) {
      padding-left: 12px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    &:nth-child(3n + 1) {
      padding-right: 16px;
    }

    &:nth-child(3n + 2) {
      padding-right: 8px;
      padding-left: 8px;
    }

    &:nth-child(3n + 3) {
      padding-left: 16px;
    }
  }
`;

const StyledSliderWrapper = styled.div`
  overflow: hidden;

  .slick-slider {
    margin: 0 -10px 33px;

    .slick-slide {
      padding-left: 10px;
      padding-right: 10px;
    }

    .slick-dots {
      bottom: -40px;

      li {
        width: 8px;
        height: 8px;
        margin: 0 4px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: 8px;
          height: 8px;

          &:before {
            position: relative;
            content: '';
            width: 8px;
            height: 8px;
            background-color: transparent;
            border: 2px solid #a4aab6;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }

      .slick-active button:before {
        background-color: #a4aab6;
      }
    }
  }
`;

export { CustomH3, CustomCol, StyledSliderWrapper, CardWrapper };
