import styled, { css } from 'styled-components';
import QuoteIcon from '@/public/assets/icons/quotes.svg';
import theme from '@/utils/styles-variables';

const Wrapper = styled.div`
  ${(props) =>
    props.colored &&
    css`
      position: relative;
      border: double 2px transparent;
      border-radius: 8px;
      background-image: linear-gradient(white, white),
        linear-gradient(to bottom, white, transparent);
      background-origin: border-box;
      background-clip: content-box, border-box;
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-left: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin: 0 24px;
  }
`;

const Inner = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.neutral.default};

  ${(props) =>
    props.colored &&
    css`
      background: linear-gradient(180deg, #f7f4f2 0%, #ffffff 100%);
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding: 32px;
    width: 451px;
    min-height: 290px;
    ${(props) =>
      props.colored &&
      css`
        box-shadow: 0 10px 30px rgba(239, 233, 230, 0.8);
      `};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding: 40px;
    width: 467px;
    min-height: 290px;
  }
`;

const Quote = styled.blockquote`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey.dark};
  margin-top: 24px;
  margin-bottom: 18px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Author = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: ${(props) => props.theme.colors.grey[800]};

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Role = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: ${(props) => props.theme.colors.grey[800]};

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

const QuoteCard = ({ quote, author, role, colored }) => {
  if (!quote && !author && !role) return null;
  return (
    <Wrapper colored={colored}>
      <Inner colored={colored}>
        <QuoteIcon fill={theme.colors.primary.default} />
        {quote && <Quote data-testid="quote">{quote}</Quote>}
        {author && <Author data-testid="author">{author}</Author>}
        {role && <Role data-testid="role">{role}</Role>}
      </Inner>
    </Wrapper>
  );
};

export default QuoteCard;
