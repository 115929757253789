import styled from 'styled-components';

const Name = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-top: 36px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 36px;
  }
  margin-bottom: 16px;
  font-size: 20px;
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
`;

export default Name;
