import Image from 'next/image';

import Wrapper from './Wrapper';
import ImageWrapper from './ImageWrapper';
import Name from './Name';

import getImgSrcWorkaround from '@/utils/images';

export default function Item({ item }) {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          src={getImgSrcWorkaround(item.image.data.attributes.url)}
          alt={item.image.data.attributes.alternativeText || ''}
          fill
          sizes="75px"
        />
      </ImageWrapper>
      <div>
        <Name>{item.name}</Name>
        <div>{item.description}</div>
      </div>
    </Wrapper>
  );
}
